.cards__container{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.cards__container--cards {
    display: flex;
    align-items: flex-end;
    justify-content: center;
    position: relative;
}

.cards__selectbar {
    display: flex;
    margin-top: 1rem;
}

.fadeStay {
    opacity: 1;
    animation: fade-stay 1.5s;
}

.fadeOut {
    opacity: 0;
    animation: fade-out-keyframes 0.25s;
}

.fadeIn750{
    animation: fade-in-keyframes 0.75s;
}

.fadeIn {
    
    animation: fade-in-keyframes 0.25s;
}

.fadeOutAndIn {
    animation: fade-in-out 0.250s;
}


.lcSlideRight {
    animation-duration: 0.25s;
    animation-name: lcSlideRight;
    animation-timing-function: ease;
}

.lcSlideLeft {
    animation-duration: 0.25s;
    animation-name: lcSlideLeft;
    animation-timing-function: ease;
}

@keyframes lcSlideRight {
    from {
        transform: 0;
    }
    to {
        transform: translate( 1.77rem);
    }
}

@keyframes lcSlideLeft {
    from {
        transform: translate( 1.77rem);
        
    }
    to {
        transform: translate(0);
    }
}

.lcSlideRightRight {
    animation-duration: 0.25s;
    animation-name: lcSlideRightRight;
    animation-timing-function: ease;
}

@keyframes lcSlideRightRight {
    from {
        transform: 0;
    }
    to {
        transform: translate( 3.54rem);
    }
}



.rcSlideRight {
    animation-duration: 0.25s;
    animation-name: rcSlideRight;
    animation-timing-function: ease;
}

.rcSlideLeft {
    animation-duration: 0.25s;
    animation-name: rcSlideleft;
    animation-timing-function: ease;
}

.rcSlideLeftRight {
    animation: slideLeftRight 0.5s;
}

.rcSlideLeftLeft {
    animation: slideLeftLeft 0.25s;
}

.slideRightLeft{
    animation: slideRightLeft 0.25s;
}

@keyframes rcSlideleft {
    from {
        transform: translate( 0px);
    }
    to {
        transform: translate( -1.77rem);
    }
}

@keyframes slideLeftLeft {
    0%{
        transform: translate(0);
    }

    100% {
        transform: translate(-3.8rem);
    }
    
}

@keyframes slideRightLeft {
    0%{
        transform: translate(0);
    }
    50% {
        transform: translate(1rem);
    }
    100% {
        transform: translate(0);
    }
    
}




@keyframes slideLeftRight {
    0%{
        transform: translate(0);
    }
    50% {
        transform: translate(-1.77rem);
    }
    100% {
        transform: translate(0);
    }
    
}

@keyframes rcSlideRight {
    from {
        transform: translate( -28.27px);
    }
    to {
        transform: 0;
    }
}



@keyframes fade-stay {
    from {
        opacity: 1
    }
    to {
        opacity: 1
    }
}

@keyframes fade-in-keyframes {
    from {
        opacity: 0
    }
    to {
        opacity: 1
    }
}

@keyframes fade-out-keyframes {
    from {
        opacity: 1
    }
    to {
        opacity: 0
    }
}

@keyframes fade-in-out {
    0% {
        opacity: 1;
    }
    50% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}


