.main__container {
    display: inline-block;
    vertical-align: bottom;
    z-index: 4;
    position: relative;
    background-color: var(--blanc);
    border-radius: 24px;
    border: solid black 0.0625rem;
    border-radius: 1.5rem 1.5rem 1.5rem 1.5rem;
    overflow: hidden;
}

.main__title {
    height: 3.064375rem;
    width: 16.875rem;
    border-radius: 24px 24px 0px 0px;
    font-size: 0.875rem;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    cursor: default;
}

.main__title--text {
    font-weight: 600;
    color: var(--noir);
}

.hidde {
    visibility: hidden;
}

.visible {
    visibility: visible;
}

.absolute {
    position: absolute;
}