.using__text{
    font-style: normal;
    font-weight: normal;
    font-size: 1.3125rem;
    line-height: 140%;
    color: var(--hygie-black);
}

.using__text--Bold--Orange{
    font-family: Cunia;
    color: var(--hygie-orange);
}

.using__text--Bold{
    font-family: Cunia;
    color: var(--hygie-black);
}

.using__button{
    padding: 0.5rem 1.5rem;
    border: solid var(--hygie-orange) 1px;
    border-radius: 1.5rem;
    background-color: var(--hygie-white);
    margin-top: 1rem;
    align-items: center;
}

.using__text--btn{
    color:var(--hygie-orange);
    font-style: normal;
    font-weight: normal;
    font-size: 0.875rem;
    line-height: 140%;
    padding: 8,24,0,0;
}

.using__button--margin-right{
    margin-left: 2.125rem;
}

.using__button:hover, .using__button:hover > .using__text--btn {
    border-color: var(--hygie-black);
    background-color: var(--hygie-light-orange);
    color : var(--hygie-black) !important;
}