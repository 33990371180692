.connect__title {
    font-style: normal;
    font-weight: 700;
    font-size: 1.3rem;
    line-height: 180%;
}

.connect__input--mobile{
    margin-top: 1.5rem;
}

.connect__title--mobile{
    margin-top: 1.5rem;
}

.connect__title--mobile {
    font-style: normal;
    font-weight: bold;
    font-size: 2.75rem;
    line-height: 140%;
}

.connect__inscription{
    color: var(--hygie-orange);
    text-decoration: underline;
    cursor : pointer;
    font-size : 0.875rem;
}

.connect__inputs{
    margin-top: 1.3rem;
}

.connect__mdpForget{
    color: var(--hygie-blue);
    text-decoration: underline;
    cursor : pointer;
}

.connect__place--mdpForget{
    display: flex;
    justify-content: flex-end;
    margin-top: 1rem;
}

.connect__title--container{
    display: flex;
    flex-direction: row;
    align-items: center;
}

.connect__btn--ressend{
    margin-top:1rem;
    margin-bottom : 1rem;    
}

@media screen and (min-width : 768px) {
    .connect__title {
        font-size: 4.75rem;
        line-height: 140%;
    }

    .connect__inscription {
        font-size : 1.3rem
    }
}