.Welcome-mssg__titre {
    font-style: normal;
    color: var(--hygie-black);
    font-size: 3rem;
    line-height: 140%;
}

.Welcome-mssg__subtitle {
    font-size : 1.3rem;
}


.Welcome-mssg__titre--orange {
    font-family: Cunia;
    color: var(--orange);
}

.Welcome-mssg__titre--bold {
    font-family: Cunia;
}

.Welcome-mssg__titre--76 {
    font-weight: 700;
    font-size: 3rem;
    /* word-break: break-all; */
}

.Welcome-mssg__titre--DE {
    font-size: 3rem;
    font-weight: 700;
}

h1 {
    font-size: 1rem;
}

@media screen and (min-width : 768px) {
    .Welcome-mssg__titre {
        font-size : 4.75rem;
    }

    .Welcome-mssg__subtitle {
        font-size : 2.25rem;
    }
}