.input__container{
    margin-top: 1rem;
    width: 100%;
}

.input__inputStyle{
    border-radius: 1rem;
    padding: 0.75rem 1.5rem ;
    background-color: var(--hygie-white);
    outline : none;
}

.input__inputNormal{
    border: solid var(--hygie-black) 0.075rem;
    width:100%;
}

.input__inputGreen{
    border: solid var(--hygie-green) 0.125rem;
    width:100%;
}

.input__inputRed{
    border: solid var(--hygie-red) 0.125rem;
    width:100%;
}

.input__nom{
    margin-left: 1rem;
}

.input__image{
    margin-left: -2rem;
}
