.view__image--description {
    width: 16.875rem;
    height: 19.623125rem;
    overflow: hidden;
   z-index: 10;
    border-top: var(--noir) 1px solid;
}

.view__image{
    z-index: 1;
}

.view__image--slide--bottom {
    animation-duration: 0.25s;
    animation-name: Slide-Bottom;
}

@keyframes Slide-Bottom {
    from {
        transform: translate( 0,200px);
    }
    to {
        transform: translate(0 ,0);
    }
}