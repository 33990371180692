.bannerMobile {
    width : 100%;
    font-style: normal;
    background-color : var(--hygie-orange) !important;
    height : 60px;
    font-size: 1rem;
    position : fixed;
    bottom : 60px;
}

.bannerDesktop {
    font-style: normal;
    background-color : var(--hygie-orange) !important;
    height : 60px;
    font-size: 1rem;
    position: absolute;
    margin-top : 3rem;
}

.banner__text {
    color : var(--hygie-white);
    font-weight: 400;
    line-height: 140%;
}

.banner__text--bold {
    font-weight: 700;
}

.banner__arrow {    
    color : var(--hygie-white);
    max-height: 24px;
}

.--fullHeight {
    height : 100%
}

/* @media screen and (min-width : 768px) {
    .dontBreak {
        display : none
    }
} */