.summcard__container {
    border: solid black 1px;
    height: 10.69rem;
    width: 7.25rem;
    border-radius: 1rem;
    overflow: hidden;
    position: relative;
    text-overflow: ellipsis;
    
    
}

.summcard__container--mobile{
    border: solid black 1px;
    height: 8.8rem;
    width: 6rem;
    border-radius: 1rem;
    overflow: hidden;
    position: relative;
    text-overflow: ellipsis;
}

.summcard__title {
    border-bottom: solid black 1px;
    height: 2.1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align:center;
    line-height: 12px;
    text-decoration: none;
   
}

.summcard__title--text {
    font-size: 0.7rem;
    color:black;
    
}

.summcard__title--text--mobile {
    font-size: 0.6rem;
    color:black;
    
}

.summcard__img--parent {
    width: inherit;  
    
}

.summcard__img {
    height: 7.25rem;
    width: 7.25rem;
}

.summcard__img--mobile {
    height : 6.26rem;
    width : 6.26rem;
}

.card__level--1 {
    background-color: var(--colorL1);
    z-index: 0;
    position: absolute;
     bottom: 0;
     height:1.25rem;
    width:inherit;
    text-align: center;
    padding-top:0.3rem;
    color:black;
    
  
}

.card__level--2 {
    background-color: var(--colorL2);
    z-index: 0;
    position: absolute;
     bottom: 0;
     height:1.25rem;
    width:inherit;
    color: #FFFFFF;
    text-align: center;
    padding-top:0.3rem;
    
    
}

.card__level--3 {
    background-color: var(--colorL3);
    z-index: 0;
    position: absolute;
     bottom: 0;
     height:1.25rem;
    width:inherit;
    color: #FFFFFF;
    text-align: center;
    padding-top:0.3rem;
   
    
    
}

.card__level--4 {
    background-color: var(--colorL4);   
    z-index: 0;
    position: absolute;
     bottom: 0;
     height:1.25rem;
    width:inherit; 
    color: #FFFFFF;
    text-align: center;
    padding-top:0.3rem;
    
   
    
}

.card__level--0{
    background-color: var(--blanc);   
    z-index: 0;
    position: absolute;
     bottom: 0;
     height:1.25rem;
    width:inherit; 
    color : var(--blanc)
}