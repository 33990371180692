body, html {
    line-height: 0.875rem;
}

.card__wrapper--1{
    

    border: solid black 0.062rem;
    border-radius: 100%;
    width: 2.8rem;
    height: 2.8rem;
    display: flex;
    justify-content: center;
    align-items: center;

}

.card__wrapper--2-4{
    

    border: solid #E9F0F8 0.062rem;
    border-radius: 100%;
    width: 2.8rem;
    height: 2.8rem;
    display: flex;
    justify-content: center;
    align-items: center;

}

.card__level {
    display: flex;
    width: 3.534375rem;
    justify-content: center;
    align-items: center;
    font-size: 0.875rem;
}

.card__text {
    font-size: 0.875rem;
    width: 12.4375rem;
    line-height: 1.0625rem;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    margin-right : 0.875rem;
}

.card__text--parent {
    display: flex;
    justify-content: space-between;
    align-content: center;
    align-items: center;
    height: 100%;
}

.card__row--level {
    width: 16.875rem;
    height: 4.89125rem;
    color: #E9F0F8;
    overflow: hidden;
    border: black 1px;
    border-style: solid;
    border-left: none;
    border-bottom: none;
    border-right: none;
    cursor: pointer;
}

.card__absolute {
    position: absolute;
    bottom: 0;
}

.card__row--level--1 {
    background-color: var(--colorL1);
    z-index: 1;
    color: #041323;
}

.card__row--level--2 {
    background-color: var(--colorL2);
    z-index: 2;
    height: 4.95rem;
}

.card__row--level--3 {
    background-color: var(--colorL3);
    z-index: 3;
    color: #FFFFFF;
}

.card__row--level--4 {
    background-color: var(--colorL4);
    border-radius: 0 0 1.5rem 1.5rem;
    border-radius: 0 0 1.35rem 1.35rem;
    z-index: 4;
    color: #FFFFFF;
}

.CardDescriptioslideTextRightLeftRight {
    animation: CDslideTextRightLeftRight 0.250s;
}

@keyframes CDslideTextRightLeftRight {
    0% {
        transform: 0;
        opacity: 1;
    }
    50% {
        transform: translate( 1.25rem);
        opacity: 0;
    }
    100% {
        transform: 0;
        opacity: 1;
    }
}

.CardDescriptioslide--Fade {
    animation: slideLeftToRight 0.25s;
}

@keyframes slideLeftToRight {
    from {
        transform: translate( -50px);
        opacity: 0;
    }
    to {
        transform: 0;
        opacity: 0.75;
    }
}

.card__hidde {
    visibility: hidden;
}

.card__slideDown1 {
    animation: slideBottom1 0.25s;
}

.card__slideDown2 {
    animation: slideBottom1 0.25s;
}

.card__slideDown3 {
    animation: slideBottom1 0.25s;
}

.card__slideDown4 {
    animation: slideBottom1 0.25s;
}

@keyframes slideBottom1 {
    from {
        transform: 0;
    }
    to {
        transform: translate(0, 14.7rem);
    }
}

@keyframes slideBottom2 {
    from {
        transform: 0;
    }
    to {
        transform: translate(0, 9.8rem);
    }
}

@keyframes slideBottom3 {
    from {
        transform: 0;
    }
    to {
        transform: translate(0, 4.9rem);
    }
}

@keyframes slideBottom4 {
    from {
        transform: 0;
    }
    to {
        transform: translate(0, 0);
    }
}

.rcSlideDescription {
    animation-duration: 0.5s;
    animation-name: rcSlideDescription;
    animation-timing-function: ease;
}

@keyframes rcSlideDescription {
    0% {
        transform: translateX( 0);
        opacity: 1;
    }
    40% {
        transform: translateX( -1.77rem);
        opacity: 0;
    }
    45% {
        transform: translateX(1.77rem);
        opacity: 0.2;
    }
    100% {
        transform: translateX( 0);
        opacity: 1;
    }
}