.desoriented__container{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 1rem;
    
}

.desoriented__explication--text{
    font-style: italic;
    font-weight: normal;
    font-size: 0.75rem;
    line-height: 160%;
    
}
.desoriented__dropdown--focus{
    background-color: var(--hygie-white);
}

.desoriented__dropdown{
    width :16.84rem;
    border : 2px solid var(--hygie-grey);
    border-radius: 1rem;
    background-color: transparent;
    text-align: center;
    margin-top:15px;
    font-family: 'Montserrat';

}


.desoriented__message--text{
    font-style: normal;
    font-weight: 600;
    font-size: 1rem;
    line-height: 160%;
}

.desoriented__Montserrat{
    font-family: 'Montserrat';
    color:blue;
}