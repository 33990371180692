.registration__input{
    margin-top: 0.3rem;
}

.registration__mrgTop{
    /*margin-top: 1.5rem;*/
}

.registration__msg{
    margin-top: 1.5rem; 
    margin-bottom: 1.5rem; 
    line-height: 140%;
}

.registration__button{
    margin-top : 2rem;
    margin-bottom: 1rem;
}

.registration__marginInputNom{
    margin-left: 1.5rem;
}


.registration__input--mobile{
    margin-top: 1.5rem;
}

.registration__title--mobile{
    margin-top: 1.5rem;
}

.registration__title {
    font-style: normal;
    font-weight: bold;
    font-size: 4.75rem;
    line-height: 140%;
}

.registration__title--mobile {
    font-style: normal;
    font-weight: bold;
    font-size: 2.75rem;
    line-height: 140%;
}

.registration__login{
    color: var(--hygie-orange);
    text-decoration: underline;
    cursor : pointer;
    font-size : 0.875rem;
}

.registration__inputColumn{
    display : flex;
    align-items: flex-end;
}

.registration__marginExplanation{
    margin-left: 0.5rem;
}

.registration__textGrey{
    color: var(--hygie-grey);/*#3F403E;*/
    font-size: 0.7368rem;
}

.registration__margin--InputRow{
    margin-top: 1.5rem;
}

.registration__CheckBox{
    height: 1.2rem;
    width: 1.2rem;
    border: var(--hygie-black) 0.15rem solid;
    -webkit-appearance:none;
    cursor: pointer;
    border-radius: 5px;
}

.registration__CheckBox--text{
    margin: 0;
    line-height: 120%;
}

.registration__marginDiv{
    margin-left: 1rem;
}
.registration__CheckBox--text--orange{
    color: var(--hygie-orange);
    margin: 0;
    margin-left: 0;
}

.registration__CheckBox--text--orange:hover{
    
    color: var(--hygie-orange);
    margin: 0;
    margin-left: 0;
    text-decoration: underline;
}

.registration__inputDiv{
    display: flex;
    align-items: center;
    flex-direction: row;

}
.registration__title--align{
    display: flex;
    flex-direction: row;
    align-items: center;
}


@media screen and (min-width : 768px) {
    .registration__title {
        font-size: 4.75rem;
        line-height: 140%;
    }

    .registration__login {
        font-size : 1.3rem
    }
}

.form-check-input {
    appearance: none;
    width : 1rem;
    height : 1rem;
    background-image : url("../../assets/SVG/Checkbox.svg") !important;
    background-size: contain;
    background-position: center !important;
    background-repeat: no-repeat !important;
    background-color: transparent !important;
    border-color: transparent !important;
}

.form-check-input:hover {
    background-image: url("../../assets/SVG/CheckboxHover.svg") !important;
}

.form-check-input:checked {
    background-image: url("../../assets/SVG/CheckBoxValid.svg") !important;
}
