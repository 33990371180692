.explication__col--left{
    padding-top:120px;  
}
.explication__col--left--mobile{
    padding-top:60px;
}

.explication__col--right{
    padding-top:170px;
    font-weight:700;
    font-size: 21px;  
}

.explication__col--right--mobile{
    padding-top:60px;
    font-weight:700;
    font-size: 21px;  
}

.explication__Hygie{
    color : var(--orange)
}

.explication__title--left{
    font-weight: 700;
    font-size:21px;
}

.explication__text--left{
    font-weight:400;
    font-size:16px;
    margin-top:15px;
    line-height: 22px;
    padding-left:8px;
}

.explication__border--left{
    border-left : var(--orange) solid 5px;
}

.explication__video--right{
    margin-top:16px;
    height : 274px;
    width:100%;
    background-color: #C4C4C4;
}

.explication__button{
    height:60px;
    width:311px;
    border: var(--orange) solid 2px;
    display:flex;
    padding-left:85px;
    align-items: center;
    color:var(--orange);
    font-weight:700;
    border-radius: 24px;
}

.explication__button--text{
    padding-left:8px;
}

.explication__button--center{
    margin-top:85px;
    display:flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}