.result__container{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.result__text{
    font-style: normal;
    font-weight: normal;
    font-size: 21px;
    line-height: 102.5%;
}

.result__score{
    font-style: normal;
    font-weight: bold;
    font-size: 48px;
    line-height: 140%;
    color:var(--orange);
}