.working__container{
    display: flex;
    flex-direction: row;
    margin-top:1.5rem;
    
}

.working__value--text{
    font-size:3rem;
    color:var(--orange);
    font-weight:bold;
    white-space: nowrap;
}
.working__left--text{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}
.working__text--bottom{
    
    font-weight:600;
    font-size:1.31rem;
}

.working__right--orange{
    color:var(--orange);
    font-weight:700;
    font-size:1.3rem;
    display:flex;
    flex-direction: row;
    align-items: center;
}

.working__right--text{
    display: flex;
    flex-direction: row;
}

.working__right{
    display: flex;
    flex-direction: column;
    width:12.5rem;
    margin-top:-0.5rem;
    margin-left:1rem;
    
}

.working__impossible--text{
    color:var(--orange)
}

.working__youCan{
    margin-bottom: 1.4rem;
}