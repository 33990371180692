.footer {
    position: fixed;
    left: 0;
    bottom: 0;
    z-index: 10;
    height: 3.75rem;
    width : 100%;
    background-color: var(--hygie-black);
}

.footer__leftContainer {
    width : 100%;
    padding : 0.5rem;
}

@media screen and (min-width : 768px) {

    .footer__leftContainer {
        padding : 0.5rem 1.5rem;
    }
}

.footer__rightContainer {
    width : 60%;
}

.svgIcons {
    height : 3.75rem;
}


.footer__row1 {
    
    align-items: center;
    z-index: 2;
}


.footer__text {
    font-family: Montserrat;
    font-style: normal;
    font-weight: bold;
    font-size: 1rem;
    line-height: 140%;
    color: var(--blanc);
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
}


.footer__button {
    position: fixed;
    right: 0;
    bottom: 0;
}

.footer--2_container{
    border-top: 1px solid #FE5000;
    position: fixed;
    left: 0;
    bottom: 0;
    z-index: 10;
    height: 4.187rem;
    display: flex;
    align-content: center;
    vertical-align: middle;
    background-color: #fff;
}

.footer--2_row{
    width: 100%;
    background: none;
}

.footer--2_col{
    width: 100%;
    justify-content: center;
    display: flex;
    align-content: center;
    vertical-align: middle;
    background: none;
}

.footer--2_button{
    width: 100%;
    border: none;
    background: none;

    align-items: center;
}

.footer--img--button{
   margin-right: 2rem;
   
}

.footer--2_txt--button{
    color: #FE5000;
    font-size: 1rem;
 }

 .footer__text--deco, .footer__text--deco:hover{
    font-size: 0.7rem;
    line-height: 140%;
    text-decoration-line: underline;
    color: #FF101F;
   
 
     
 }

