.result__margin--top{
    margin-top: 2rem;
}

.resultrow__margin--top{
    margin-top : 2rem;
    
}

.resultrow__margin--top--mobile{
    margin-top : 3.43rem
    
}

.result__center--card{
    display: flex;
    justify-content: center;
    margin-top: 1rem;
}

.result__text{
    display: flex;
    align-items:flex-start;
    flex-direction: column;
}

.result__div--click{
    cursor: pointer;
    text-decoration: none;
  
}
.result__div--click:focus, .result__div--click:hover, .result__div--click:visited, .result__div--click:link, .result__div--click:active {
    text-decoration: none;
}

.resultkatz__desorientation--message{
    font-weight :bold;
    font-size :21px;
    margin-top:24px;
}

.result__text--short{
    font-size: 1rem;
    margin-top :1.5rem;
    font-weight: 400;
}

.result__text--message{
    font-size: 1rem;
    margin-top :1.5rem;
    font-weight: 400;
    line-height: 1.38rem;
    max-width :20rem;
}
.result__text--orange{
    color : var(--orange);
    font-weight: bold;
}

.--paddingBottom {
    padding-bottom : 10rem;
}