
.select--bar__container{
    
    width: 15,625rem;
    height: 1.062rem;
    cursor : pointer;
    
}

.select--bar__ball{
    position : absolute;
    
}
