.input {
   
    border-radius: 1rem;
    width: 100%;
    height: 2.75rem;
    border: 1px solid #3F403E;
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 1rem;
    line-height: 1.25rem;
    
    display: flex;
    align-items: center;
    padding-left: 1.5rem;
}

.input--onFocus {
    box-shadow: 0.15625rem 0.15625rem #000000;
    background-color: #FFF67F;
}

.parentInput{
    width: 100%;
    
}

.ParentInputImage{
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.marginLeft{
    margin-left: 1rem;
}

.inputText{
    font-size: 1rem;
    line-height: 1.21875rem;
    
    font-weight: 700;
}

.inputText--grey{
    color: #3F403E;
    
}

.inputText--black{
    color: #100E10;
    
}

.inputTextNormal{
    font-size: 1rem;
    line-height: 1.21875rem;
    
}


.pen{

    position: absolute;
   margin-right: 1rem;
}

.text {
    height: 1.25rem;
    left: 1.5rem;
    top: 0rem;
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 1rem;
    line-height: 1.25rem;
    color: #000000;
    margin-left: 1.5rem;
}

