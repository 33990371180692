
.Welcome__FirstRow{
    /* margin-top: 1.5rem; */
}


.Welcome__SecondRow{
    margin-top: 11.125rem;
    
}

.--paddingL-0 {
    padding-left: 0;
}

.rowHeight {
    height : 50vh;
}