.wrapper--button__button{
    background: var(--orange);
    border-radius: 1.4375em;
    padding: 0.75rem 2.25rem;
    align-content: center;
    position: relative;
    border: none;
    margin-top: 1.25em;
}

.wrapper--button__wrapper{
    
    position: absolute;
    border: 1px solid black;
    border-radius: 1.4375em;
    z-index: 1;

    left: 2.21%;
    right: -2.21%;
    top: -8.7%;
    bottom: 8.7%;
}

.wrapper--button__text{
    font-style: normal;
    font-weight: bold;
    font-size: 1rem;
    line-height: 140%;
}

.wrapper--button__button:disabled{
  
        background: #F9B190;
        border-radius: 1.4375em;
        padding: 0.75rem 2.25rem;
        align-content: center;
        position: relative;
        border: none;
        margin-top: 1.25em;
    

}


/* ########################## animated button ############################### */

.animated-button{
    display: flex;
    position:relative;
    font-size:1rem;
    /* font-family : var(--hygie-font-bold); */
    font-weight : 700;
    flex-direction:row;
    justify-content: center;
    align-items: center;
    background-color:var(--hygie-orange);
    padding: 0.8rem 2.25rem; 
    border-radius:3rem;
    /* margin-top:1.5rem; */
    text-decoration: none;
    border-color : transparent !important;
    border-style: none;
    color : var(--hygie-black);
}


.animated-button::before{
    content:"";
    display: block;
    border: 0.15rem solid var(--hygie-black);
    border-radius:3rem;
    width:100%;
    height:100%;
    position:absolute;
    top:-0.25rem;
    left:0.25rem;
    box-shadow: 0 0 0 var(--hygie-brown);
    transition: 0.25s all ease;
    pointer-events: none;
}

.animated-button:hover::before{
    top:0;
    left:0;
    transition: 0.25s all ease;
}

.animated-button:hover{
    box-shadow: -0.35rem 0.35rem 0 var(--hygie-brown);
    text-decoration: none !important;
    transition: 0.25s all ease;
    color : var(--hygie-black);
}

.animated-button:active {
    background: var(--hygie-black);
    color:var(--hygie-white);
    transform:translate(-.35rem,.35rem);
    box-shadow: 0 0 0 var(--hygie-brown);
    transition: 0.25s all ease;
}

a:hover {
    text-decoration: none;
}
