.continence__container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 2.25rem;
}


.continence__text {
    font-style: normal;
    font-weight: 600;
    font-size: 1rem;
    line-height: 160%;
}

.continence__text--switch{
    display: flex;
    width:16.94rem;
    justify-content: space-between;
}

.continence__explication--text{
    font-style: italic;
    font-weight: normal;
    font-size: 0.75rem;
    line-height: 160%;
}
.continence__switch{
    display: flex;
    flex-direction: row;
    align-items: flex-end;
}
