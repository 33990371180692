
.header__row{
    /* padding-top: 1.875em; */
    padding : 1rem 0;
}

.header__button{
    display: flex;
    position:relative;
    font-size:1rem;
    /* font-family : var(--hygie-font-bold); */
    font-weight : 700;
    flex-direction:row;
    justify-content: center;
    align-items: center;
    background-color:var(--hygie-orange);
    padding: 1rem; 
    border-radius:3rem;
    text-decoration: none;
    border-color : transparent !important;
    border-style: none;
    color : var(--hygie-black);
}


.header__button::before{
    content:"";
    display: block;
    border: 0.15rem solid var(--hygie-black);
    border-radius:3rem;
    width:100%;
    height:100%;
    position:absolute;
    top:-0.25rem;
    left:-0.25rem;
    box-shadow: 0 0 0 var(--hygie-brown);
    transition: 0.25s all ease;
    pointer-events: none;
}

.header__button:hover::before{
    top:0;
    left:0;
    transition: 0.25s all ease;
}

.header__button:hover{
    box-shadow: 0.35rem 0.35rem 0 var(--hygie-brown);
    text-decoration: none !important;
    transition: 0.25s all ease;
    color : var(--hygie-black);
}

.header__button:active {
    background: var(--hygie-black);
    color:var(--hygie-white);
    transform:translate(.35rem,.35rem);
    box-shadow: 0 0 0 var(--hygie-brown);
    transition: 0.25s all ease;
}

/* .header__button{
    background-color: var(--hygie-orange);
    width: 2.875em;
    height: 2.875em;
    border-radius: 100%; 
    border: none;

    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
}

.header__wrapper{
    max-width: 2.875em;
    width: 2.875em;
    max-height: 2.875em;
    height: 2.875em;
    position: absolute;
    border: 0.0625em solid var(--hygie-black);
    border-radius: 100%;
    z-index: 1;
    left: 0.0625em;
    top: 0.625em;
    margin-left: 0.6875em;
    margin-top: -0.875em;
}

.header__phone{
    z-index: 10;
} */
