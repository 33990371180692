.side--card__right--card {
    width: 5.065625rem;
    min-height: 19.623125rem;
    font-weight: 600;
    height: 19.623125rem;
    border-radius: 0px 24px 24px 0px;
    border-style: solid;
    border-color: var(--noir);
    border-width: 1px;
    background-color: var(--orange);
    z-index: 2;
    display: flex;
    font-size: 0.875rem;
    justify-content: flex-end;
    align-items: center;
    margin-left: -3.29875rem;
    cursor: pointer;
}

.side--card__left--card {
    width: 5.065625rem;
    height: 19.623125rem;
    font-weight: 600;
    cursor: default;
    font-size: 0.875rem;
    border-radius: 24px 0px 0px 24px;
    border-style: solid;
    border-color: var(--noir);
    border-width: 1px;
    background-color: var(--orange);
    z-index: 1;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-right: -3.29875rem;
    cursor: pointer;
}

.side--card__left--card--2 {
    width: 3.534375rem;
    height: 16.55876rem;
    border-radius: 24px 0px 0px 24px;
    border-style: solid;
    border-color: var(--orange);
    border-width: 1px;
    background-color: var(--colorSideCardL3);
    z-index: 1;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-right: -1.875rem;
    cursor: default;
    cursor: pointer;
}

.side--card__right--card--2 {
    width: 3.534375rem;
    height: 16.55876rem;
    border-radius: 0 1.5rem 1.5rem 0;
    border-style: solid;
    border-color: black;
    border-width: 1px;
    background-color: var(--colorSideCardL3);
    z-index: 1;
    margin-left: -1.7675rem;
    font-size: 0.75rem;
    justify-content: flex-end;
    align-items: center;
    display: flex;
    white-space: normal;
    cursor: pointer;
}

.side--card__txt--right--Card {
    writing-mode: vertical-lr;
    font-size: 0, 875rem;
    color: var(--noir);
    margin-right: 0.42rem;
}

.side--card__txt--left--Card {
    font-size: 0, 875rem;
    writing-mode: vertical-lr;
    color: var(--noir);
    transform: rotate(180deg);
    margin-left: 0.420625rem;
}

.leftCard--Margin {
    margin-left: -1.7675rem;
}

.side--card__right--card--absolute {
    position: absolute;
    right: 0;
}

.side--card__left--card--absolute {
    position: absolute;
    left: 0;
}

.side--card__visible {
    visibility: visible;
}

.side--card__hidde {
    visibility: hidden;
}