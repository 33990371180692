

.using__header{
    display: flex;
    margin-top: 4rem;
    flex-direction: row;
    align-items: center;
}

.using__title{
    font-size: 3rem;
    font-weight: 600;
    margin-left: 1rem;
    text-align: center;
}

.using__title--mobile{
    font-size: 1rem;
    font-weight: 600;
    margin-left: 1rem;
    text-align: center;
}


.using__iframe{
    border: none;
    height: 60vh;
    width: 100%;

}

.using__webview{
    height: 90vh;
}
