.forgotPwd__title{
    margin-top: 3.375rem;
    font-size: 3rem;
    font-weight: 600;
}

.forgotPwd__explication--row{
    margin-top: 3.75rem;

}

.forgotPwd__mail--row{
    margin-top: 3rem;

}

.forgotPwd__text{
    
    font-size: 21px;
    line-height: 140%;
}