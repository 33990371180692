.quota__title{
    font-size : 4.9rem;
    font-weight : bold;
    line-height: 4rem;
  
}

.quota__title--mobile{
    font-size : 2.9rem;
    font-weight : bold;
    line-height: 2.5rem;
}

.quota__firstrow{
    margin-top: 6.25rem;
}
.quota__firstrow--mobile{
    margin-top: 1rem;
}
.quota__utilisation{
    margin-top :2rem;
    font-size:2.25rem;
    
}

.quota_secondrow{
    margin-top: 14rem;
}

.quota_secondrow--mobile{   
    margin-top: 5rem;
}

.quota__message{
    line-height: 1.38rem;
}
