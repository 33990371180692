:root {

    --noir: #000000;
    --orange: #FE5000;
    --blanc: #FEF4EF;
    --colorL1 : #689CD4;
    --colorL2 : #1264BC;
    --colorL3 : #092E56;
    --colorL4 : #041323;
    --colorSideCardL3 : #FE8F5C;

    --hygie-orange: #FE5000;
    --hygie-light-orange : #FE8F5C;
    --hygie-black: #100E10;
    --hygie-pure-black: #000;
    --hygie-white : #FDFFF9;
    --hygie-brown : #5D1E00;
    --hygie-grey : #46453D;
    --hygie-cream : #FEF4EF;
    --hygie-yellow : #FFF67F;
    --hygie-light-yellow : #FFFDDD;
    
    --hygie-lightBlue : #689CD4;
    --hygie-blue : #1264BC;
    --hygie-navy : #092E56;
    --hygie-darkBlue : #041323;

    --hygie-green: #137709;
    --hygie-red: #FF101F;

    --hygie-font-regular : "Montserrat-Regular";
    --hygie-font-semi-bold : "Montserrat-Bold";
    --hygie-font-bold : "Montserrat-Bold";
    --hygie-font-light : "Montserrat-light";

    --hygie-font-cunia : "Cunia";
  }