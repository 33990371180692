
.ConfirmSub__Titre1{
    font-size: 2.25rem;
}

.ConfirmSub__Titre2{
    font-weight: 700;
    font-size: 4.75rem;
}

.ConfirmSub__margin--1row{
    margin-top: 4rem;
}

.ConfirmSub__button{
    border: solid var(--hygie-black) 1px;
    border-radius: 1rem;
    padding:  0.5rem 1.5rem;    
    background: none;
}

.ConfirmSub__p{
    line-height: 1.6rem;
}

.ConfirmSub__textBold{
    font-weight: 600;
}

.ConfirmSub__button--text{
    color: var(--hygie-black);
}