.connect__text {
    font-style: normal;
    font-weight: normal;
    font-size: 1rem;
    line-height: 140%;
    color: var(--hygie-black);
}

.connect--btn--register {
    display: inline-block;
    border: none;
    background: none;
    align-items: center;
    text-align: center;
    padding: 0.75rem 1rem;
    margin-top: 1.25rem;
    min-height: 2.5rem;
}

.connect__text--btn--register {
    color: var(--hygie-orange);
    text-decoration-line: underline;
    text-align: center; 
}