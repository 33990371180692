.input__normal {
    border-radius: 1rem;
    width: 100%;
    height: 2.75rem;
    border: 1px solid #3F403E;
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 1rem;
    line-height: 1.25rem;
    background-color: #FDFFF9;
    display: flex;
    align-items: center;
    padding-left: 1.5rem;
    font-size: 1rem;
    line-height: 1.25rem;
    color: var(--noir);
    font-weight: bold;
}

.input__normal:focus{
    box-shadow: 0.15625rem 0.15625rem var(--noir);
    background-color: #FFF67F;
    border-radius: 1rem;
    outline : none
}

::placeholder {
    font-weight: normal;
    color: #3F403E;
  }



.input__margin--top {
    margin-top: 3.14125rem;
}

.input__btn--pen {
    position: absolute;
    padding-right : 1rem;
}

.input__parent {
    margin-top: 0.5rem;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}
